body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.marquee-container {
  display: flex;
  

  overflow: hidden;
  white-space: nowrap;
  
}

.marquee {


  animation: marquee 10s linear infinite;
}


/* Keep the bot fixed at bottom-right */
/* #my-widget-root {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 9999 !important; 
} */

/* Make the bot circular */
/* #my-widget-root img {
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
  cursor: pointer !important;
} */

/* Force modal to be visible */
/* #my-widget-root .modal-container {
  position: fixed !important;
  bottom: 100px !important; 
  right: 20px !important;
  z-index: 10000 !important; 
  display: flex !important; 
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: auto !important; 
} */

/* Prevent modal from being hidden */
/* #my-widget-root .modal-container[style*="display: none"] {
  display: flex !important;
} */

/* If bot script adds unwanted styles, reset them */
/* #my-widget-root div {
  position: relative !important; 
}

#my-widget-root .modal-container > div {
  max-width: 350px !important;
} */
